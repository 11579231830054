import React from 'react';
import AuthWrapper from '../../components/layout/AuthWrapper';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import SendForm from '../../components/organisms/send-form/SendFormPage';

const SendFormPage = () => {
  return (
    <AuthWrapper>
      <Head title="Send form" />
      <Layout
        section="templates"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/list-forms', label: 'Form templates' },
          { label: 'Send form' },
        ]}
      >
        <SendForm userType="employer" formType="send" />
      </Layout>
    </AuthWrapper>
  );
};

export default SendFormPage;
